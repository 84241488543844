import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ENVIRONMENT } from 'src/config-global';
import { CONSTANT_VARIABLES } from 'src/constants/constants-variables';
// auth
import { AuthGuard } from 'src/auth/guard';
import AdminGuard from 'src/auth/guard/admin-guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

import { CheckoutProvider } from 'src/sections/customer/checkout/context';

import OverlayDetailsPage from 'src/pages/admin/overlays/overlay-details';
import CreateOverlayPage from 'src/pages/admin/overlays/create-overlay';
import OverlayEditPage from 'src/pages/admin/overlays/edit-overlay';
import AdminOrAgentGuard from 'src/auth/guard/admin-agent-guard';
import OverviewDashboardPage from 'src/pages/admin/overview/overview';
import FinancialsDashboardPage from 'src/pages/admin/financials/financials';

import { paths } from '../paths';
// import AdminOrAuditorGuard from 'src/auth/guard/admin-producer-guard';

// ----------------------------------------------------------------------

// dashboard pages
const OverlayPage = lazy(() => import('src/pages/admin/overlays/overlay-list'));

// OVERVIEW
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));

const ClientOrdersPage = lazy(() => import('src/pages/admin/client-order/list'));
const ClientOrderCreatePage = lazy(() => import('src/pages/admin/client-order/new'));
const ClientOrderEditPage = lazy(() => import('src/pages/admin/client-order/edit'));
const ClientOrderDetailsPage = lazy(() => import('src/pages/admin/client-order/details'));
// const ProviderPage = lazy(() => import('src/pages/admin/producer-order/list'));
const ProducerOrderCreatePage = lazy(() => import('src/pages/admin/producer-order/new'));
const ProducerOrderEditPage = lazy(() => import('src/pages/admin/producer-order/edit'));
// const ProducerOrderDetailsPage = lazy(() => import('src/pages/admin/producer-order/details'));
// products
const AllTheProductsPage = lazy(() => import('src/pages/admin/product/list'));
const ReorderProductsPage = lazy(() => import('src/pages/admin/reorder-products/list'));
const ProductPerformancePage = lazy(() => import('src/pages/admin/product-performance/list'));
const ProductCreatePage = lazy(() => import('src/pages/admin/product/new'));
const ProductEditPage = lazy(() => import('src/pages/admin/product/edit'));
const ProducersAdminPage = lazy(() => import('src/pages/admin/producer/list'));
const ProducerCreatePage = lazy(() => import('src/pages/admin/producer/new'));
const ProducerEditPage = lazy(() => import('src/pages/admin/producer/edit'));
const ProductDetailsTabsPage = lazy(() => import('src/pages/admin/product/details'));
const ProducerDetailsTabsPage = lazy(() => import('src/pages/admin/producer/details'));
// user
const UserListPage = lazy(() => import('src/pages/admin/user/list'));
const UserCreatePage = lazy(() => import('src/pages/admin/user/new'));
const UserEditPage = lazy(() => import('src/pages/admin/user/edit'));
const ClientListPage = lazy(() => import('src/pages/admin/client/list'));
const ClientCreatePage = lazy(() => import('src/pages/admin/client/new'));
const ClientEditPage = lazy(() => import('src/pages/admin/client/edit'));
const ClientDetailsPage = lazy(() => import('src/pages/admin/client/details'));

const ReservationListPage = lazy(() => import('src/pages/admin/reservation/list'));
const ReservationCreatePage = lazy(() => import('src/pages/admin/reservation/new'));
const ReservationEditPage = lazy(() => import('src/pages/admin/reservation/edit'));
const TastingListPage = lazy(() => import('src/pages/admin/tasting/list'));
const TastingCreatePage = lazy(() => import('src/pages/admin/tasting/new'));
// const TastingEditPage = lazy(() => import('src/pages/admin/tasting/edit'));
const TastingDetailsPage = lazy(() => import('src/pages/admin/tasting/details'));
const ClientReferralsListPage = lazy(() => import('src/pages/admin/client-referral/list'));
const ClientReferralCreatePage = lazy(() => import('src/pages/admin/client-referral/new'));
const ClientReferralEditPage = lazy(() => import('src/pages/admin/client-referral/edit'));
const ClientReferralDetailsPage = lazy(() => import('src/pages/admin/client-referral/details'));
const UserDetailsPage = lazy(() => import('src/pages/admin/user/details'));
// statistics pages
const SalesStaticPage = lazy(() => import('src/pages/admin/statistics/sale-statics'));
const AnnualStatisticsPage = lazy(() => import('src/pages/admin/statistics/annual-statistics'));
const ProductStatisticsPage = lazy(() => import('src/pages/admin/statistics/product-statistics'));
const ProfitabiltyPage = lazy(() => import('src/pages/admin/statistics/profitability'));
const BottlesFlowPage = lazy(() => import('src/pages/admin/statistics/bottles-flow'));

const WineTypeListPage = lazy(() => import('src/pages/admin/wine-type/list'));
const WineTypeCreatePage = lazy(() => import('src/pages/admin/wine-type/new'));
const WineTypeEditPage = lazy(() => import('src/pages/admin/wine-type/edit'));
const WineTypeDetailsPage = lazy(() => import('src/pages/admin/wine-type/details'));

// ----------------------------------------------------------------------

const isStagingOrLocalDev =
  ENVIRONMENT === CONSTANT_VARIABLES.STAGE ||
  ENVIRONMENT === CONSTANT_VARIABLES.DEVELOP ||
  ENVIRONMENT === CONSTANT_VARIABLES.PRODUCTION
    ? [
        {
          path: paths.admin.statisticTypes.root,
          children: [
            {
              path: paths.admin.statisticTypes.sale_stats,
              element: (
                <AdminGuard>
                  <SalesStaticPage />
                </AdminGuard>
              ),
              index: true,
            },
            {
              path: paths.admin.statisticTypes.annual_stats,
              element: (
                <AdminGuard>
                  <AnnualStatisticsPage />
                </AdminGuard>
              ),
              index: true,
            },
            {
              path: paths.admin.statisticTypes.product_stats,
              element: (
                <AdminGuard>
                  <ProductStatisticsPage />
                </AdminGuard>
              ),
              index: true,
            },
            {
              path: paths.admin.statisticTypes.profitability,
              element: (
                <AdminGuard>
                  <ProfitabiltyPage />
                </AdminGuard>
              ),
              index: true,
            },
            {
              path: paths.admin.statisticTypes.bottles_flow,
              element: (
                <AdminGuard>
                  <BottlesFlowPage />
                </AdminGuard>
              ),
              index: true,
            },
            {
              path: paths.admin.statisticTypes.product_performance,
              element: (
                <AdminGuard>
                  <ProductPerformancePage />
                </AdminGuard>
              ),
            },
          ],
        },
      ]
    : [];

export const adminRoutes = [
  {
    element: (
      <AuthGuard>
        <CheckoutProvider>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </CheckoutProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.admin.root,
        children: [
          {
            path: paths.admin.users.root,
            element: (
              <AdminGuard>
                <UserListPage />
              </AdminGuard>
            ),
            index: true,
          },
          {
            path: paths.admin.users.new,
            element: (
              <AdminGuard>
                <UserCreatePage />
              </AdminGuard>
            ),
          },
          {
            path: `${paths.admin.users.root}/:slug`,
            element: (
              <AdminGuard>
                <UserDetailsPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.users.edit(':slug'),
            element: (
              <AdminGuard>
                <UserEditPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.clients.root,
            element: (
              <AdminOrAgentGuard>
                <ClientListPage />
              </AdminOrAgentGuard>
            ),
          },
          {
            path: `${paths.admin.clients.root}/:slug`,
            element: (
              <AdminGuard>
                <ClientDetailsPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.clients.new,
            element: (
              <AdminGuard>
                <ClientCreatePage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.clients.edit(':slug'),
            element: (
              <AdminGuard>
                <ClientEditPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.reservations.root,
            element: (
              <AdminGuard>
                <ReservationListPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.dashboard.overlays.root,
            element: (
              <AdminGuard>
                <OverlayPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.dashboard.overview,
            element: (
              <AdminGuard>
                <OverviewDashboardPage />
              </AdminGuard>
            ),
          },
          {
            path: `${paths.admin.dashboard.overlays.root}/:id`,
            element: (
              <AdminGuard>
                <OverlayDetailsPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.dashboard.financials,
            element: (
              <AdminGuard>
                <FinancialsDashboardPage />
              </AdminGuard>
            ),
          },
          {
            path: `${paths.admin.dashboard.overlays.new}`,
            element: (
              <AdminGuard>
                <CreateOverlayPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.dashboard.overlays.edit(':id'),
            element: (
              <AdminGuard>
                <OverlayEditPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.reservations.new,
            element: (
              <AdminGuard>
                <ReservationCreatePage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.reservations.edit(':client_slug', ':sku'),
            element: (
              <AdminGuard>
                <ReservationEditPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.tastings.root,
            element: (
              <AdminGuard>
                <TastingListPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.clientReferrals.root,
            element: (
              <AdminOrAgentGuard>
                <ClientReferralsListPage />
              </AdminOrAgentGuard>
            ),
          },
          {
            path: paths.admin.clientReferrals.new,
            element: (
              <AdminGuard>
                <ClientReferralCreatePage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.clientReferrals.edit(':slug'),
            element: (
              <AdminGuard>
                <ClientReferralEditPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.clientReferrals.details(':slug'),
            element: (
              <AdminGuard>
                <ClientReferralDetailsPage />
              </AdminGuard>
            ),
          },
        ],
      },
      {
        path: paths.admin.root,
        children: [
          {
            path: paths.admin.orderTypes.clientOrders.root,
            element: (
              <AdminOrAgentGuard>
                <ClientOrdersPage />
              </AdminOrAgentGuard>
            ),
            index: true,
          },
          {
            path: paths.admin.orderTypes.clientOrders.new,
            element: (
              <AdminOrAgentGuard>
                <ClientOrderCreatePage />
              </AdminOrAgentGuard>
            ),
          },
          {
            path: paths.admin.orderTypes.clientOrders.edit(':order_code'),
            element: (
              <AdminOrAgentGuard>
                <ClientOrderEditPage />
              </AdminOrAgentGuard>
            ),
          },
          {
            path: paths.admin.orderTypes.clientOrders.details(':order_code'),
            element: (
              <AdminOrAgentGuard>
                <ClientOrderDetailsPage />
              </AdminOrAgentGuard>
            ),
          },
          // {
          // path: paths.admin.orderTypes.producerOrders.root,
          //   element: (
          //     <AdminGuard>
          //       <ProviderPage />
          //     </AdminGuard>
          //   ),
          // },
          {
            path: paths.admin.orderTypes.producerOrders.new,
            element: (
              <AdminGuard>
                <ProducerOrderCreatePage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.orderTypes.producerOrders.edit(':order_code'),
            element: (
              <AdminGuard>
                <ProducerOrderEditPage />
              </AdminGuard>
            ),
          },
          // {
          //   path: paths.admin.orderTypes.producerOrders.details(':order_code'),
          //   element: (
          //     <AdminGuard>
          //       <ProducerOrderDetailsPage />
          //     </AdminGuard>
          //   ),
          // },
        ],
      },
      {
        path: paths.admin.root,
        children: [
          {
            path: paths.admin.products.root,
            element: (
              <AdminGuard>
                <AllTheProductsPage />
              </AdminGuard>
            ),
            // index: true,
          },
          {
            path: paths.admin.reorderProducts.root,
            element: (
              <AdminGuard>
                <ReorderProductsPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.products.new,
            element: (
              <AdminGuard>
                <ProductCreatePage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.products.edit(':sku'),
            element: (
              <AdminGuard>
                <ProductEditPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.products.details(':sku'),
            element: (
              <AdminGuard>
                <ProductDetailsTabsPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.producers.root,
            element: (
              <AdminGuard>
                <ProducersAdminPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.producers.new,
            element: (
              <AdminGuard>
                <ProducerCreatePage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.producers.edit(':slug'),
            element: (
              <AdminGuard>
                <ProducerEditPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.producers.details(':slug'),
            element: (
              <AdminGuard>
                <ProducerDetailsTabsPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.wineTypes.root,
            element: (
              <AdminGuard>
                <WineTypeListPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.wineTypes.new,
            element: (
              <AdminGuard>
                <WineTypeCreatePage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.wineTypes.edit(':id'),
            element: (
              <AdminGuard>
                <WineTypeEditPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.wineTypes.details(':id'),
            element: (
              <AdminGuard>
                <WineTypeDetailsPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.tastings.root,
            element: (
              <AdminGuard>
                <TastingListPage />
              </AdminGuard>
            ),
          },
          {
            path: paths.admin.tastings.new,
            element: (
              <AdminGuard>
                <TastingCreatePage />
              </AdminGuard>
            ),
          },
          // {
          //   path: paths.admin.tastings.edit(':id'),
          //   element: (
          //     <AdminGuard>
          //       <TastingEditPage />
          //     </AdminGuard>
          //   ),
          // },
          // {
          //   path: paths.admin.tastings.details(':id'),
          //   element: (
          //     <AdminGuard>
          //       <TastingDetailsPage />
          //     </AdminGuard>
          //   ),
          // },
          {
            path: `${paths.admin.tastings.root}/:slug`,
            element: (
              <AdminGuard>
                <TastingDetailsPage />
              </AdminGuard>
            ),
          },
        ],
      },
      ...isStagingOrLocalDev,
    ],
  },
];
