import { Box, Container, Typography } from '@mui/material';
import { t } from 'i18next';
import { useGetCurrentQuarterStats } from 'src/api/admin';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { paths } from 'src/routes/paths';
import CurrentQuarterStatsSection from './view/current-quarter-stats-section';

export default function FinancialsView() {
  const { data: currentQuarterData } = useGetCurrentQuarterStats();
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('dashboard')}
        links={[
          ...[
            { name: t('admin') },
            { name: t('financials'), href: paths.admin.dashboard.financials },
          ],
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        alignItems={{ xs: 'flex-end', sm: 'start' }}
        gap={8}
      >
        <Box display="flex" flexDirection="column" width="100%" gap={3}>
          <>
            <Typography variant="h4">{t('current_quarter_statistics')}</Typography>
            {currentQuarterData && (
              <CurrentQuarterStatsSection currentQuarterdata={currentQuarterData} />
            )}
          </>
        </Box>
      </Box>
    </Container>
  );
}
