import Head from 'src/components/head';
import { useLocales } from 'src/locales';
import FinancialsDashboardView from 'src/sections/admin/financials/financials-dashboard-view';

export default function FinancialsDashboardPage() {
  const { t } = useLocales();

  return (
    <>
      <Head title={`${t('financials')}`} />
      <FinancialsDashboardView />
    </>
  );
}
