// mui
import { Box, Container, Typography } from '@mui/material';
import { format } from 'date-fns';
// component
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';

// routes
import { paths } from 'src/routes/paths';
import { t } from 'i18next';
import {
  useGetAdministrationStats,
  useGetCurrentMonthStats,
  useGetLogisticsStats,
} from 'src/api/admin';

import LogisticsSection from './view/logistics-section';
import AdministrationSection from './view/administration-section';
import CurrentMonthStatsSection from './view/current-month-stats-section';

export default function DashboardView() {
  const now = new Date();
  const currentDate = format(now, 'yyyy-MM-dd');

  const { data: logisticsData } = useGetLogisticsStats({ currentDate });
  const { data: administrationData } = useGetAdministrationStats();
  const { data: currentMonthData } = useGetCurrentMonthStats();

  const settings = useSettingsContext();
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('dashboard')}
        links={[
          ...[{ name: t('admin') }, { name: t('overview'), href: paths.admin.dashboard.overview }],
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        alignItems={{ xs: 'flex-end', sm: 'start' }}
        gap={8}
      >
        <Box display="flex" flexDirection="column" width="100%" gap={3}>
          <Typography variant="h4">{t('logistics')}</Typography>
          {logisticsData && <LogisticsSection {...logisticsData} />}
        </Box>

        <Box display="flex" flexDirection="column" width="100%" gap={3}>
          <Typography variant="h4">{t('administration')}</Typography>
          {administrationData && <AdministrationSection administrationData={administrationData} />}
        </Box>

        <Box display="flex" flexDirection="column" width="100%" gap={3}>
          <Typography variant="h4">{t('current_month_statistics')}</Typography>
          {currentMonthData && <CurrentMonthStatsSection currentMonthData={currentMonthData} />}
        </Box>
      </Box>
    </Container>
  );
}
