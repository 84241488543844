// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import Tooltip from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';
// auth
// import { useAuthContext } from 'src/auth/hooks';
// routes
// import { paths } from 'src/routes/paths';
// import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
// import Logo from 'src/components/logo';
import { ENVIRONMENT } from 'src/config-global';
import { ENVIRONMENTS } from 'src/constants/misc';
import Footer from '../dashboard/footer';
// ----------------------------------------------------------------------

// const METHODS = [
//   {
//     id: 'jwt',
//     label: 'Jwt',
//     path: paths.auth.jwt.login,
//     icon: '/assets/icons/auth/ic_jwt.svg',
//   },
//   {
//     id: 'firebase',
//     label: 'Firebase',
//     path: paths.auth.firebase.login,
//     icon: '/assets/icons/auth/ic_firebase.svg',
//   },
// ];

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  // const { method } = useAuthContext();

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  // const renderLogo = (
  //   <Logo
  //     sx={{
  //       zIndex: 9,
  //       position: 'absolute',
  //       m: { xs: 2, md: 5 },
  //       top: 1,
  //       left: 2,
  //     }}
  //   />
  // );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      // flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 1,
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94,
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '80vh',
        }}
      >
        <Box
          component="img"
          alt="auth"
          src={image || '/assets/images/login/natives_login.png'}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="column"
      sx={{
        marginTop: { xs: ENVIRONMENT === ENVIRONMENTS.prod ? 0 : '-1.5rem', sm: '-1.5rem' },
        minHeight: ENVIRONMENT === ENVIRONMENTS.prod ? '100vh' : 'calc(100vh - 24px)',
      }}
      position="relative"
    >
      {/* {renderLogo} */}

      <Stack direction="row" sx={{ flex: 1 }}>
        {mdUp && renderSection}
        {renderContent}
      </Stack>
      <Footer isAuthPage />
    </Stack>
  );
}
