import { useLocales } from 'src/locales';
import { ICurrentQuarterStats } from 'src/types';
import { Box } from '@mui/material';
import MedianStatCard from './stats-card';

export default function CurrentQuarterStatsSection({
  currentQuarterdata,
}: {
  currentQuarterdata: ICurrentQuarterStats;
}) {
  const { t } = useLocales();

  const formattedData = {
    dso: currentQuarterdata.dso,
    dpo: currentQuarterdata.dpo,
  };

  const titlesMap: { [key: string]: string } = {
    dso: 'dso',
    dpo: 'dpo',
    ccc: 'ccc',
  };

  const iconsMap: { [key: string]: string } = {
    dso: 'mdi:calendar-clock',
    dpo: 'mdi:calendar-check',
    ccc: 'mdi:sync',
  };

  const renderCurrentQuarterStats = Object.entries(formattedData).map(([key, value]) => {
    const title = titlesMap[key] || key;
    const icon = iconsMap[key];

    return <MedianStatCard key={key} value={value} subTitle={t(title)} icon={icon} />;
  });

  return (
    <Box
      gap={2}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(3, 1fr)',
      }}
      mt={4}
      mb={4}
    >
      {renderCurrentQuarterStats}
    </Box>
  );
}
