import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import { useSnackbar } from 'src/components/snackbar';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';

import axiosInstance, { endpoints } from 'src/utils/axios';

import { ILogisticsStats } from 'src/types/misc';
import CustomTable from '../list/custom-table';

export default function LogisticsSection({
  ordersToBeShipped,
  ordersShippedToday,
  ordersShippedYesterday,
  ordersToBeShippedTomorrow,
}: ILogisticsStats) {
  const { enqueueSnackbar } = useSnackbar();

  // FIXME: we should not be writing axios calls in the component like this, do not copy this code futher
  const downloadCSV = async () => {
    try {
      const response = await axiosInstance(`${endpoints.admin.overview.logisticsCsv}`, {
        params: { date: format(new Date(), 'yyyy-MM-dd') },
        responseType: 'blob',
      });

      saveAs(new Blob([response.data]), `logistics.csv`);
    } catch (error) {
      console.error('Error downloading the CSV', error.response);
      if (error?.response) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  // FIXME: we should not be writing axios calls in the component like this, do not copy this code futher
  const sendToLogistic = async () => {
    try {
      await axiosInstance(`${endpoints.admin.overview.logisticsCsvEmail}`, {
        params: { date: format(new Date(), 'yyyy-MM-dd') },
      });

      enqueueSnackbar(t('email_sent_successfully'), { variant: 'success' });
    } catch (error) {
      console.error('Error sending to logistic', error.response);
      if (error?.response) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  // Determine if there is data to show in the table and conditionally show the button
  const isDataAvailableForCSV = ordersShippedToday && ordersShippedToday.length > 0;

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('ordini_da_spedire')}
      </Typography>
      <CustomTable data={ordersToBeShipped} />
      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
        {t('ordini_spediti_domani')}
      </Typography>
      <CustomTable data={ordersToBeShippedTomorrow} />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
          {t('ordini_spediti_oggi')}
        </Typography>
        <Box display="flex" flexDirection="row" sx={{ gap: 1 }}>
          {isDataAvailableForCSV && (
            <Button onClick={downloadCSV} variant="contained" sx={{ height: '18%', mb: 2, mt: 4 }}>
              {`${t('download')} CSV`}
            </Button>
          )}
          {isDataAvailableForCSV && (
            <Button
              onClick={sendToLogistic}
              variant="contained"
              sx={{ height: '18%', mb: 2, mt: 4 }}
            >
              {t('send_to_logistic')}
            </Button>
          )}
        </Box>
      </Box>
      <CustomTable data={ordersShippedToday} />{' '}
      {/* Add margin top to CustomTable for additional space */}
      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
        {t('ordini_spediti_ieri')}
      </Typography>
      <CustomTable data={ordersShippedYesterday} />
    </Box>
  );
}
